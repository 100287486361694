<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <WindowTitleBar title="Back Volume Creation" @loadData="loadData" @close_window="closeThis">{{ setTitle() }}      </WindowTitleBar>

      <div class="card-body" >

        <form style="padding: 0.5em;">

          <div class="form-group row" >
            <label for="txtCode" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Code</label>
            <div class="col-md-2 col-sm-4">
              <input type="text" class="form-control" id="txtCode" maxlength="10" autocomplete="off" v-if="backvolume" v-model="backvolume.code">
            </div>

            <div col-md-3 style="background-color: #4ca950;">
              <button type="button" class="btn btn-secondary btn-icon rounded-pill"><i class="icon-search4"></i></button>
            </div>
          </div>

          <!--Book Name & Titile-->
          <div class="form-group row" >
            <label for="txtName" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Title</label>
            <div class="col-md-5 col-sm-10">
              <input type="text" class="form-control" id="txtName" maxlength="100" autocomplete="off" v-if="backvolume" v-model="backvolume.name">
            </div>

            <label for="txt_Title" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Sub Title</label>
            <div class="col-md-5 col-sm-10">
              <input type="text" class="form-control" id="txt_Title" maxlength="100" autocomplete="off" v-if="backvolume" v-model="backvolume.title">
            </div>

          </div>

          <!--Publisher-->
          <div class="form-group row" >
            <label for="txtPublisher" class="col-md-1 col-form-label font-weight-semibold">Publisher</label>
            <div class="col-md-5">
              <select type="text" class="form-control" id="txtPublisher" v-if="backvolume" v-model="backvolume.publisher_id">
                <option v-for="pub in publishers" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

            <label for="txtCategory" class="col-md-1 col-form-label font-weight-semibold">Category</label>
            <div class="col-md-5">
              <select class="form-control" id="txtCategory" v-if="backvolume" v-model="backvolume.category_id">
                <option v-for="pub in categories" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

          </div>


          <!--Author-->
          <div class="form-group row" >
            <label class="col-md-1 col-form-label font-weight-semibold">Authors</label>
            <div class="col-md-8 ">
              <select id="cmbAuthors" class="form-control select-access-multiple-value" multiple="multiple" data-fouc  >
                <option v-for="pub in authors" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

            <label class="col-md-1 col-form-label font-weight-semibold" data-popup="popover" title="Language" data-trigger="hover" data-content="Select the Content Language">Lang</label>
            <div class="col-md-2">
              <select class="form-control" id="txtLanguage" v-if="backvolume" v-model="backvolume.language" data-popup="popover" title="Language" data-trigger="hover" data-content="Select the Content Language">
                <option value="en" selected>English</option>
                <option value="ta">Tamil</option>
                <option value="ma">Malayam</option>
                <option value="hi">Hindi</option>
              </select>
            </div>


          </div>

          <!--Price, Pages Location-->
          <div class="form-group row" >

            <label for="txtDepartment" class="col-md-1 col-form-label font-weight-semibold">Depart</label>
            <div class="col-md-2">
              <select class="form-control" v-if="backvolume" v-model="backvolume.department_id">
                <option v-for="pub in departments" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>


            <label for="txtLocation" class="col-md-1 col-form-label font-weight-semibold">Location</label>
            <div class="col-md-2">
              <input id="txtLocation" type="text" class="form-control" autocomplete="off" placeholder="Book Rack Location" v-if="backvolume" v-model="backvolume.rack">
            </div>


            <label for="txtPrice" class="col-md-1 col-form-label font-weight-semibold">Price</label>
            <div class="col-md-2">
              <input type="number" class="form-control  text-right" id="txtPrice" min="0" v-if="backvolume" v-model="backvolume.price">
            </div>

            <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Pages</label>
            <div class="col-md-2">
              <input type="number" class="form-control text-right" id="txtPages" min="0" v-if="backvolume" v-model="backvolume.pages">
            </div>

          </div>

          <!--Price, Pages Location-->
          <div class="form-group row" >
            <label for="txtSubject" class="col-md-1 col-form-label font-weight-semibold">Subject</label>
            <div class="col-md-5">
              <select class="form-control" v-if="backvolume" v-model="backvolume.subject_id">
                <option v-for="pub in subjects" v-bind:value="pub.id">
                  {{ pub.name }}
                </option>
              </select>
            </div>

            <label for="txtPrice" class="col-md-1 col-form-label font-weight-semibold">Currency</label>
            <div class="col-md-2">
              <select class="form-control" v-if="backvolume" v-model="backvolume.currency">
                <option value="INR" selected>Indian Rupee ₹</option>
                <option value="USD" >United States Dollar $</option>
                <option value="GBP" >United Kingdom Pound £</option>
                <option value="SGD" >Singapore Dollar $</option>
                <option value="CAD" >Canada Dollar $</option>
                <option value="AUD" >Australia Dollar $</option>
              </select>
            </div>

            <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Con.Rate</label>
            <div class="col-md-2">
              <input type="number" class="form-control text-right" min="1" v-if="backvolume" v-model="backvolume.conv_rate">
            </div>

          </div>


          <SaveButton title="Add" @handle_save_action="perform_save_action"/>


        </form>


      </div>

    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'BackVolumeForm',
  components: {
    WindowTitleBar,
    SaveButton
  },
  data () {
    return {
      current_access_no: 0,
      backvolume: JSON.parse('{"id":0,"status":"Available","type":"BackVolume","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}'),
      publishers:[],
      authors:[],
      categories: [],
      subjects:[],
      departments: [],
    }
  },
  store,
  component: {

  },
  props: {
    mybook: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Available","type":"BackVolume","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}')
    }
  },
  beforeMount () {
    this.backvolume = this.mybook;
    // $('#cmbAuthors').select2('val', [1002,1003]);
  },
  created () {
    this.$data.backvolume = JSON.parse('{"id":0,"status":"Available","type":"BackVolume","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}')
    this.$data.backvolume.year = (new Date()).getFullYear();
  },
  mounted () {
    const self = this;
    this.$data.backvolume.year = (new Date()).getFullYear();
    this.loadCategories();
    this.loadAuthors();
    this.loadPublishers();
    this.loadDepartments();
    this.loadSubjects();

    $('#cmbAuthors').select2({
      placeholder: "Select Author...",
      minimumResultsForSearch: Infinity
    });

    $('[data-popup=tooltip-custom]').tooltip({
      template: '<div class="tooltip"><div class="arrow border-teal"></div><div class="tooltip-inner bg-teal text-white"></div></div>'
    });

    $('#txtCode').focus();

  },
  methods: {
    closeThis () {
      this.$emit('backvolume_window_closed');
      if(this.backvolume.id > 0 ) {
        this.$router.push("/")
      }
    },
    setTitle () {
      return this.backvolume.id == 0 ? 'Back Volume Creation' : 'Back Volume Updation'
    },
    loadData(){
      try {
        this.loadCategories();
        this.loadAuthors();
        this.loadPublishers();
      }catch (e) {
        alert(e);
      }
    },
    choose_file(evt){
      const self = this;
      const file = evt.target.files[0];
      const reader = new FileReader();
      reader.onloadend = function() {
        const base64 = reader.result;
        self.backvolume.image = base64;
      };
      reader.readAsDataURL(file);
    },
    loadPublishers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadAuthors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.authros = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/authors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.authors = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    get_current_access_no(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      self.$data.current_access_no = "";
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/books/current_accessno`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.current_access_no = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadDepartments(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.departments = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadCategories(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/categories`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
    loadSubjects () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      this.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');

      self.$data.subjects = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.subjects =  resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    },
    perform_save_action(){
      const self = this;
      try {

        self.$data.backvolume.authors = [];
        $("#cmbAuthors :selected").each(function () {
          let author = {"id": parseInt(this.value), "name": "" };
          self.$data.backvolume.authors.push(author);
        });

        if( self.$data.backvolume.title == null) {
          self.$data.backvolume.title = "";
        }

        if( self.$data.backvolume.code == null) {
          self.$data.backvolume.code = "";
        }

        if( self.$data.backvolume.edition == null) {
          self.$data.backvolume.edition = "";
        }

        if( self.$data.backvolume.isbn == null) {
          self.$data.backvolume.isbn = "";
        }

        if( self.$data.backvolume.rack == null) {
          self.$data.backvolume.rack = "";
        }

        self.$data.backvolume.qty = 1;

        if(self.$data.backvolume.code.trim().length == 0) {
          alert('Invalid Back Volume Code');
          return;
        }else if(self.$data.backvolume.name.trim().length == 0) {
          alert('Invalid Back Volume Title');
          return;
        }else if( parseInt(self.$data.backvolume.qty) < 1 ) {
          alert('Invalid Back Volume Quantity');
          return;
        }else if( parseInt(self.$data.backvolume.category_id) < 1 ) {
          alert('Invalid Back Volume Category');
          return;
        }else if( parseInt(self.$data.backvolume.publisher_id) < 1 ) {
          alert('Invalid Back Volume Publisher');
          return;
        }else if( parseInt(self.$data.backvolume.publisher_id) < 1 ) {
          alert('Invalid Back Volume Publisher');
          return;
        }else if( $("#cmbAuthors").val().toString().length == 0 ) {
          alert('Invalid Authors');
          return;
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.backvolume.type = 'BackVolume';

        const requestOptions = {
          method: (self.$data.backvolume.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.backvolume)
        }

        console.log(JSON.stringify(self.$data.backvolume));

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/backvolume`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtCode').focus()}, timer:1500 });
            self.backvolume = JSON.parse('{"id":0,"status":"Available","type":"BackVolume","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
            self.$emit('backvolume_saved',resp.data);
            $('#txtCode').focus();
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtCode').focus()}, timer:3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtCode').focus()}, timer:3000 });
        }).finally(()=>{
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00!important;
    color: #0a0a0a!important;
  }
</style>
